import { serviceRouteNames } from './routeNames';

export const servicesSectionData = [
  {
    title: 'Bespoke Software Development',
    link: serviceRouteNames.BespokeSoftwareDevelopment,
    text: 'We are a full-stack agile team developing unique and tailored solutions for companies and startups to address their <span class=text-red-underline><strong>business challenges</strong></span>',
    src: require('@/assets/svg/services/BespokeSoftwareDevelopment.svg'),
  },
  {
    title: 'System Integration and API Development',
    link: serviceRouteNames.SystemIntegrationAndAPIDevelopment,
    text: 'We develop integration services to connect any application and data source and we design, build, promote, and protect APIs',
    src: require('@/assets/svg/services/SystemIntegrationandAPIDevelopment.svg'),
    component: 'SystemIntegrationAndAPIDevelopment',
  },
  {
    title: 'Cloud Migration',
    link: serviceRouteNames.CloudMigration,
    text: 'We perform reliable cloud-to-cloud and on-premise server-to-cloud migrations and commence the cloud migration journey with you',
    src: require('@/assets/svg/services/CloudMigration.svg'),
  },
  {
    title: 'Mobile App Development',
    link: serviceRouteNames.MobileAppDevelopment,
    text: 'We create unique and tailored cross platform mobile apps that are user-friendly and designed with the flexibility to scale and adapt in-line with <span class=text-red-underline><strong>your business needs</strong></span>',
    src: require('@/assets/svg/services/MobileAppDevelopment.svg'),
  },
  {
    title: 'IT Support',
    link: serviceRouteNames.ITSupport,
    text: 'We cover all essential work needed on your software applications so that they work efficiently, effectively and securely',
    src: require('@/assets/svg/services/ITSupport.svg'),
  },
  {
    title: 'IT Outstaffing',
    link: serviceRouteNames.ITOutstaffing,
    text: 'Great Attractor Software provides a worldwide IT Outstaffing service created to help the clients scale up the teams and get the reliable remote developers fast. <strong>The choice of <span class=text-red-underline>collaboration option</span> is all yours</strong>',
    src: require('@/assets/svg/services/ITOutstaffing.svg'),
  },
];
