import { industries } from './industries';
import { companyRouteNames } from './routeNames';
import { servicesSectionData } from './services';

export const NAVBAR_VALUE = [
  {
    name: 'Services',
    link: '/services',
    links: servicesSectionData.map(({ title, link }) => ({
      name: title,
      link,
    })),
  },
  {
    name: 'Industries',
    link: '/industries',
    links: industries.map(({ title, link }) => ({
      name: title,
      link,
    })),
  },
];

export const ABOUT = {
  name: 'Company',
  link: '/company',
  links: [
    {
      name: 'About us',
      link: companyRouteNames.aboutUs,
    },
    {
      name: 'Careers',
      link: companyRouteNames.careers,
    },
  ],
};
