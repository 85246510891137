<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 14 8"
    fill="none"
    :class="'uid-arrow uid-arrow-' + direction"
  >
    <path
      d="M1 1L7 7L13 1"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 14,
    },
    color: {
      type: String,
      default: '#ababab',
    },
    height: {
      type: Number,
      default: 8,
    },
    direction: {
      type: String,
      default: 'down',
    },
  },
});
</script>

<style lang="scss" scoped>
.uid-arrow {
  transition: transform 0.3s var(--easing);

  &-up {
    transform: rotate(180deg);
  }

  &-left {
    transform: rotate(90deg);
  }

  &-right {
    transform: rotate(-90deg);
  }
}
</style>
