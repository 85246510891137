import { industries } from '@/constants/industries';
import { companyRouteNames, rootRouteNames } from '@/constants/routeNames';
import { servicesSectionData } from '@/constants/services';

const getComponent = (item) => {
  return item.component || item.title.replace(/\s/g, '');
};

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Main/Home.vue'),
  },
  {
    path: rootRouteNames.privacyPolicy,
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy/Home.vue'),
  },
  {
    path: companyRouteNames.careers,
    name: 'Careers',
    component: () => import('../views/Careers/Home.vue'),
  },
  {
    path: companyRouteNames.aboutUs,
    name: 'About us',
    component: () => import('../views/AboutUs/Home.vue'),
  },
  {
    path: '/use-cases',
    name: 'Use cases',
    component: () => import('../views/UseCases/Home.vue'),
    children: [],
  },
  {
    path: '/use-cases/:projectName',
    component: () => import('../views/ProjectPage/Home.vue'),
  },
  {
    path: '/industries',
    name: 'Industries',
    redirect: industries[0].link,
    children: industries.map((item) => ({
      path: item.link,
      name: item.title,
      component: () => import(`../views/${getComponent(item)}/Home.vue`),
      meta: {
        title: item.title,
      },
    })),
  },
  {
    path: '/services',
    name: 'Services',
    redirect: servicesSectionData[0].link,
    children: servicesSectionData.map((item) => ({
      path: item.link,
      name: item.title,
      component: () => import(`../views/${getComponent(item)}/Home.vue`),
      meta: {
        title: item.title,
      },
    })),
  },
];
