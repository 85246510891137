<script setup>
import { NAVBAR_VALUE, ABOUT } from '@/constants/navbar';
import Popper from 'vue3-popper';
import Arrow from './svg/Arrow';
import { vScrollTo } from '@/directives/scrollTo';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
</script>

<template>
  <nav class="navbar">
    <router-link class="navbar__img_container" to="/">
      <img src="@/assets/svg/favicon.svg" alt="favicon" />
    </router-link>
    <div class="navbar__buttons">
      <Popper
        v-for="tab in NAVBAR_VALUE"
        :key="tab.name"
        hover
        :class="{ active: route.path.includes(tab.link) }"
        style="cursor: pointer"
        class="navbar__buttons-tabs"
        offset-distance="0"
        placement="bottom-start"
      >
        <span class="name" v-text="tab.name" />
        <template #content>
          <router-link
            v-for="value in tab.links"
            :key="value.name"
            :to="value.link"
            style="cursor: pointer"
          >
            <p
              :class="{ active: route.path.includes(value.link) }"
              class="text-button navbar__buttons-tabs-tab"
              v-text="value.name"
            />
          </router-link>
        </template>
      </Popper>
      <div
        :class="{ active: route.path.includes('/use-cases') }"
        class="navbar__buttons-tabs"
      >
        <router-link
          to="/use-cases"
          class="navbar__buttons-tabs-tab-use_cases name"
        >
          Portfolio
        </router-link>
      </div>
      <popper
        hover
        style="cursor: pointer"
        class="navbar__buttons-tabs"
        :class="{ active: route.path.includes(ABOUT.link) }"
        offset-distance="0"
        placement="bottom-start"
      >
        <span class="name" v-text="ABOUT.name" />
        <template #content>
          <router-link
            v-for="value in ABOUT.links"
            :key="value.name"
            :to="value.link"
            style="cursor: pointer"
          >
            <p
              :class="{ active: route.path.includes(value.link) }"
              class="navbar__buttons-tabs-tab"
              v-text="value.name"
            />
          </router-link>
        </template>
      </popper>

      <div class="navbar__buttons-tabs">
        <a
          v-scroll-to="'contacts'"
          class="navbar__buttons-contact_btn name text-button"
        >
          Contacts
        </a>
      </div>
      <a
        href="mailto:info@greatattractor.ae"
        class="navbar__buttons-button text-button"
      >
        Let’s Talk
        <Arrow class="ml-20" />
      </a>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.name {
  display: flex;
  align-items: center;
  height: 96px;
  padding: 0 3.2rem;

  @media (max-width: 1200px) {
    padding: 0 2.4rem;
  }
}
::v-deep(.popper) {
  max-width: 38rem;
  background-color: var(--color-white);
}

.gray {
  padding-left: 4rem !important;
  color: var(--color-gray-dark) !important;
}

.navbar {
  position: fixed;
  z-index: 100;
  top: 0;
  display: flex;
  width: 100%;
  height: 9.6rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-light);
  background-color: var(--color-white);

  &__img_container {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 4.5rem;
    border-right: 1px solid var(--color-gray-light);
  }

  &__buttons {
    height: 100%;
    display: flex;
    align-items: center;
    &-tabs {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid transparent !important;

      &:hover,
      &.active {
        background-color: #fafbfc;
        border-bottom: 1px solid var(--color-blue) !important;

        & .name {
          color: var(--color-gray-dark);
        }
      }

      &:first-child {
        height: 100%;
      }

      &-tab {
        padding: 1.6rem 1.6rem;
        color: var(--color-black);
        transition: all 0.3s;

        &:hover,
        &.active {
          background-color: #f0f0f0;
          color: var(--color-gray-dark);
        }
      }
    }

    &-contact_btn {
      border: 0;
      background: none;
      cursor: pointer;
    }

    &-button {
      height: 100%;
      padding: 0 4.8rem;
      align-items: center;
      display: flex;
      border: 0;
      margin-left: 3.2rem;
      background: linear-gradient(
        95.87deg,
        #7476ed -31.2%,
        #6e93e3 20.75%,
        #5fd3cb 111.29%
      );
      color: var(--color-white);
      cursor: pointer;

      img {
        padding-left: 2rem;
      }

      &:hover {
        background: #6b6de7;
      }
    }
  }

  @media (max-width: $tablet-large-width) {
    display: none;
  }
}
</style>
