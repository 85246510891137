import { indusctiesRouteNames } from './routeNames';

export const oldIndustries = [
  {
    name: 'Digital HR',
    src: 'digital',
    link: indusctiesRouteNames.DigitalHR,
  },
  {
    name: 'Enterprise',
    src: 'enterprise',
  },
  {
    name: 'eCommerce',
    src: 'eCommerce',
    link: indusctiesRouteNames.eCommerce,
  },
  {
    name: 'HoReCa',
    src: 'HoReCa',
  },
  {
    name: 'Urban/Public sector',
    src: 'urban',
    link: indusctiesRouteNames.UrbanPublicSector,
  },
  {
    name: 'Technology Solutions',
    src: 'technology',
  },
];

export const industries = [
  {
    title: 'Digital HR',
    link: indusctiesRouteNames.DigitalHR,
    text: 'We help our clients transform their businesses by integrating existing HR solutions or developing new ones. <strong>Digital HR services we can develop exactly for <span class=text-red-underline>your needs</span></strong>',
  },
  {
    title: 'eCommerce',
    link: indusctiesRouteNames.eCommerce,
    text: 'We offer a personalised and unique online sales service that exceeds your customers & expectations and optimises your business processes',
  },
  {
    title: 'Urban / Public sector',
    link: indusctiesRouteNames.UrbanPublicSector,
    text: 'We help our clients to transform public services by enriching their solutions with the highest standards of usability, flexibility, and scalability. We create eGovernment solutions that deliver impact.',
    component: 'UrbanPublicSector',
  },
];
